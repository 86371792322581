body.loading #loading {
  display: block;
}

body.loading #loading-overlay {
  display: block;
}

@import '@fontsource/dm-serif-text/400.css';
@import '@fontsource/lato/300.css';
@import '@fontsource/lato/400.css';
@import '@fontsource/lato/700.css';
@import '@fontsource/sarabun/300.css';
@import '@fontsource/sarabun/400.css';
@import '@fontsource/sarabun/500.css';
@import '@fontsource/sarabun/700.css';
